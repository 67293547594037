import React, { useState, useEffect } from "react"
import ReactLoading from "react-loading"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import NavStatic from "../containers/navStatic/NavStatic"
import ReactCompareImage from "react-compare-image"
import ReactPlayer from "react-player"

import ModalVideo from "react-modal-video"
import StarRatings from "react-star-ratings"
import Footer from "../components/footer/Footer"
import Layout from "../components/layout"
import demoVideo from "../images/mp4/product-demo-quick.mp4"
import afterCompare from "../images/new-way.png"
import beforeCompare from "../images/old-way.png"
import "./merchants.css"

const Homepage = ({ data }) => {
  const [isDemoVidOpen, setIsDemoVidOpen] = useState(false)
  console.log(isDemoVidOpen)
  console.log(data)
  const heroImg = data.heroImg.childImageSharp.fluid
  const feature1 = data.feature1.childImageSharp.fluid
  const feature2 = data.feature2.childImageSharp.fluid
  const feature3 = data.feature3.childImageSharp.fluid
  const feature4 = data.feature4.childImageSharp.fluid
  const feature5 = data.feature5.childImageSharp.fluid
  const feature6 = data.feature6.childImageSharp.fluid
  const emailReturn = data.emailReturn.childImageSharp.fluid
  const shopifyLogo = data.shopifyLogo.childImageSharp.fixed
  const shopifyPartners = data.shopifyPartners.childImageSharp.fixed
  const cxLogo1 = data.cxLogo1.childImageSharp.fluid
  const cxLogo2 = data.cxLogo2.childImageSharp.fluid
  const cxLogo3 = data.cxLogo3.childImageSharp.fluid

  let featureTitle1 = "Grow customer LTV with our unique size passport network"
  let featureBody1 =
    "Your store benefits from our network of pre-sized shoppers. Using Sizify across the web for their sizing needs. Shoppers are confident of fit from the first visit."
  let featureTitle2 = "A new era without returns of up to 40%"
  let featureBody2 =
    "Previously, more sales meant more returns. With our size technology, customers choose the right size the first-time."
  let featureTitle3 = "One-click conversion"
  let featureBody3 =
    "Customers add items to cart at higher rates with size recommendations"
  let featureTitle4 = "No idea of size? No problem."
  let featureBody4 =
    "We have multi size discovery options for your customers. Allowing you to serve a variety of shoppers without increasing returns"
  let soloFeatureTitle = "The #1 obstacle for merchants selling clothes online"
  let soloFeatureBody =
    'Solving "the sizing issue" means more revenue, less returns & a better shopping experience for your customers'
  let checklistTitle = "Any of this sounds familiar? Then you need Sizify"

  let checklistNeeds
  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://eocampaign1.com/form/980355c0-7a79-11ef-b086-1b887cc257ee.js"
    script.async = true
    script.setAttribute("data-form", "980355c0-7a79-11ef-b086-1b887cc257ee")
    const scriptContainer = document.getElementById("script-container")
    if (scriptContainer) {
      scriptContainer.appendChild(script)
    }

    return () => {
      if (scriptContainer && scriptContainer.contains(script)) {
        scriptContainer.removeChild(script)
      }
    }
  }, [])

  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://eocampaign1.com/form/bd9e135e-7a7b-11ef-9e75-bb35713dc90a.js"
    script.async = true
    script.setAttribute("data-form", "bd9e135e-7a7b-11ef-9e75-bb35713dc90a")
    const scriptContainer = document.getElementById("script-hero")
    if (scriptContainer) {
      scriptContainer.appendChild(script)
    }

    return () => {
      if (scriptContainer && scriptContainer.contains(script)) {
        scriptContainer.removeChild(script)
      }
    }
  }, [])

  if (true) {
    checklistNeeds = (
      <ul className="homepage-checklist-ul">
        <li className="homepage-checklist-li merchants-how-blurbPara">
          <div>
            <svg
              style={{ marginRight: "0.8rem" }}
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="checkboxIconTitle"
              stroke="#75CB4D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
              color="#75CB4D"
            >
              {" "}
              <title id="checkboxIconTitle">Checkbox (selected)</title>{" "}
              <rect
                x="21"
                y="3"
                width="18"
                height="18"
                rx="1"
                transform="rotate(90 21 3)"
              />{" "}
              <path d="M6.66666 12.6667L9.99999 16L17.3333 8.66669" />{" "}
            </svg>
          </div>
          <p>I need to decrease return costs</p>
        </li>

        <li className="homepage-checklist-li merchants-how-blurbPara">
          <div>
            <svg
              style={{ marginRight: "0.8rem" }}
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="checkboxIconTitle"
              stroke="#75CB4D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
              color="#75CB4D"
            >
              {" "}
              <title id="checkboxIconTitle">Checkbox (selected)</title>{" "}
              <rect
                x="21"
                y="3"
                width="18"
                height="18"
                rx="1"
                transform="rotate(90 21 3)"
              />{" "}
              <path d="M6.66666 12.6667L9.99999 16L17.3333 8.66669" />{" "}
            </svg>
          </div>
          <p> I want to improve conversion rates</p>
        </li>

        <li className="homepage-checklist-li merchants-how-blurbPara">
          <div>
            <svg
              style={{ marginRight: "0.8rem" }}
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="checkboxIconTitle"
              stroke="#75CB4D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
              color="#75CB4D"
            >
              {" "}
              <title id="checkboxIconTitle">Checkbox (selected)</title>{" "}
              <rect
                x="21"
                y="3"
                width="18"
                height="18"
                rx="1"
                transform="rotate(90 21 3)"
              />{" "}
              <path d="M6.66666 12.6667L9.99999 16L17.3333 8.66669" />{" "}
            </svg>
          </div>
          <p>I think sizing issues are losing me sales</p>
        </li>

        <li className="homepage-checklist-li merchants-how-blurbPara">
          <div>
            <svg
              preserveAspectRatio="xMidYMid meet"
              style={{ marginRight: "0.8rem" }}
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="checkboxIconTitle"
              stroke="#75CB4D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
              color="#75CB4D"
            >
              {" "}
              <title id="checkboxIconTitle">Checkbox (selected)</title>{" "}
              <rect
                x="21"
                y="3"
                width="18"
                height="18"
                rx="1"
                transform="rotate(90 21 3)"
              />{" "}
              <path d="M6.66666 12.6667L9.99999 16L17.3333 8.66669" />{" "}
            </svg>
          </div>
          <p>I am using my refund policy to make up for sizing problems</p>
        </li>
        <li className="homepage-checklist-li merchants-how-blurbPara">
          <div>
            <svg
              style={{ marginRight: "0.8rem" }}
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="checkboxIconTitle"
              stroke="#75CB4D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
              color="#75CB4D"
            >
              {" "}
              <title id="checkboxIconTitle">Checkbox (selected)</title>{" "}
              <rect
                x="21"
                y="3"
                width="18"
                height="18"
                rx="1"
                transform="rotate(90 21 3)"
              />{" "}
              <path d="M6.66666 12.6667L9.99999 16L17.3333 8.66669" />{" "}
            </svg>
          </div>
          <p>I want less support tickets</p>
        </li>
        <li className="homepage-checklist-li merchants-how-blurbPara">
          <div>
            <svg
              style={{ marginRight: "0.8rem" }}
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="checkboxIconTitle"
              stroke="#75CB4D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
              color="#75CB4D"
            >
              {" "}
              <title id="checkboxIconTitle">Checkbox (selected)</title>{" "}
              <rect
                x="21"
                y="3"
                width="18"
                height="18"
                rx="1"
                transform="rotate(90 21 3)"
              />{" "}
              <path d="M6.66666 12.6667L9.99999 16L17.3333 8.66669" />{" "}
            </svg>
          </div>
          <p>
            {" "}
            I worry customers are not buying more because they are uncertain
            about fit
          </p>
        </li>

        <li className="homepage-checklist-li merchants-how-blurbPara">
          <div>
            <svg
              style={{ marginRight: "0.8rem" }}
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="checkboxIconTitle"
              stroke="#75CB4D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
              color="#75CB4D"
            >
              {" "}
              <title id="checkboxIconTitle">Checkbox (selected)</title>{" "}
              <rect
                x="21"
                y="3"
                width="18"
                height="18"
                rx="1"
                transform="rotate(90 21 3)"
              />{" "}
              <path d="M6.66666 12.6667L9.99999 16L17.3333 8.66669" />{" "}
            </svg>
          </div>
          <p>Shoppers are adding to cart but not completing checkout</p>
        </li>
        <li className="homepage-checklist-li merchants-how-blurbPara">
          <div>
            <svg
              style={{ marginRight: "0.8rem" }}
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="checkboxIconTitle"
              stroke="#75CB4D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
              color="#75CB4D"
            >
              {" "}
              <title id="checkboxIconTitle">Checkbox (selected)</title>{" "}
              <rect
                x="21"
                y="3"
                width="18"
                height="18"
                rx="1"
                transform="rotate(90 21 3)"
              />{" "}
              <path d="M6.66666 12.6667L9.99999 16L17.3333 8.66669" />{" "}
            </svg>
          </div>
          <p>
            I want my size chart to be accessible to customers with visual
            impairments
          </p>
        </li>
        <li className="homepage-checklist-li merchants-how-blurbPara">
          <di>
            <svg
              style={{ marginRight: "0.8rem" }}
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="checkboxIconTitle"
              stroke="#75CB4D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
              color="#75CB4D"
            >
              {" "}
              <title id="checkboxIconTitle">Checkbox (selected)</title>{" "}
              <rect
                x="21"
                y="3"
                width="18"
                height="18"
                rx="1"
                transform="rotate(90 21 3)"
              />{" "}
              <path d="M6.66666 12.6667L9.99999 16L17.3333 8.66669" />{" "}
            </svg>
          </di>
          <p>
            I want to increase LTV by ensuring my customer sizing experience is
            positive
          </p>
        </li>

        <li className="homepage-checklist-li merchants-how-blurbPara">
          <div>
            <svg
              style={{ marginRight: "0.8rem" }}
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="checkboxIconTitle"
              stroke="#75CB4D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
              color="#75CB4D"
            >
              {" "}
              <title id="checkboxIconTitle">Checkbox (selected)</title>{" "}
              <rect
                x="21"
                y="3"
                width="18"
                height="18"
                rx="1"
                transform="rotate(90 21 3)"
              />{" "}
              <path d="M6.66666 12.6667L9.99999 16L17.3333 8.66669" />{" "}
            </svg>
          </div>
          <p>I'd scale sales faster if my customers trusted clothing fit</p>
        </li>

        <li className="homepage-checklist-li merchants-how-blurbPara">
          <div>
            <svg
              style={{ marginRight: "0.8rem" }}
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="checkboxIconTitle"
              stroke="#75CB4D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
              color="#75CB4D"
            >
              {" "}
              <title id="checkboxIconTitle">Checkbox (selected)</title>{" "}
              <rect
                x="21"
                y="3"
                width="18"
                height="18"
                rx="1"
                transform="rotate(90 21 3)"
              />{" "}
              <path d="M6.66666 12.6667L9.99999 16L17.3333 8.66669" />{" "}
            </svg>
          </div>
          <p>
            I want to improve my store's environmental impact by reducing
            returns
          </p>
        </li>
        <li className="homepage-checklist-li merchants-how-blurbPara">
          <div>
            <svg
              style={{ marginRight: "0.8rem" }}
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-labelledby="checkboxIconTitle"
              stroke="#75CB4D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
              color="#75CB4D"
            >
              {" "}
              <title id="checkboxIconTitle">Checkbox (selected)</title>{" "}
              <rect
                x="21"
                y="3"
                width="18"
                height="18"
                rx="1"
                transform="rotate(90 21 3)"
              />{" "}
              <path d="M6.66666 12.6667L9.99999 16L17.3333 8.66669" />{" "}
            </svg>
          </div>
          <p>I don't want customers worrying about clothing fit</p>
        </li>
      </ul>
    )
  }

  let videoModal
  if (typeof window !== `undefined`) {
    videoModal = (
      <ModalVideo
        channel="vimeo"
        autoplay
        isOpen={isDemoVidOpen}
        videoId="678763708"
        onClose={() => setIsDemoVidOpen(false)}
      />
    )
  }

  return (
    <Layout>
      <NavStatic />

      <div
        className="merchants-header_container"
        style={{ justifyContent: "center", paddingBottom: "0" }}
      >
        <h1
          className="merchants-header_titleBig"
          style={{ textAlign: "center" }}
        >
          Upgrade to AI sizing to increase AOV & reduce returns
        </h1>

        <h3 className="merchants-header_subTitleThin">
          Unmatched sizing speed & accuracy using 2 in 1 size recommendations &
          charts
        </h3>

        <div
          className="merchants-header_textContainer"
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            className="merchants-header_optionContainer2"
            style={{ flexWrap: "wrap", justifyContent: "center" }}
          >
            <a
              className="navbar-text"
              href="https://sizify-demo-shop.myshopify.com/products/unisex-jersey-tee"
            >
              <button
                className="btn btn-solid-outline-secondary"
                style={{
                  padding: "0.85rem 1rem",
                  margin: "0",
                  color: "hsla(210, 12%, 62%, 1)",
                }}
              >
                Demo store
              </button>
            </a>
            <div id="script-hero" style={{ width: "100%" }}></div>
            {/* <button className="btn btn-solid-outline-secondary" style={{margin:'1rem 0 0 0'}} onClick={()=> {
                          setIsDemoVidOpen(true)
                          }}>
                        Watch: Demo in 3 minutes
                        </button>
          
                        {videoModal} */}
          </div>
          <div>
            <div className="homepage-social">
              <Img className="homepage-social-img" fluid={cxLogo1} />
              <Img
                className="homepage-social-img"
                style={{ marginLeft: "-0.5rem" }}
                fluid={cxLogo2}
              />
              <Img
                className="homepage-social-img"
                style={{ marginLeft: "-0.5rem" }}
                fluid={cxLogo3}
              />

              <div className="homepage-social-star-container">
                <StarRatings
                  rating={5}
                  starDimension="20px"
                  starSpacing="2px"
                  starRatedColor="hsla(221, 70%, 70%, 1)"
                  numberOfStars={5}
                  name="rating"
                  style={{ wrap: "nowrap" }}
                />
                <h3 className="homepage-social-text ">
                  (5/5 rating. 600+ Shopify stores served)
                </h3>
              </div>
            </div>
          </div>

          {/* <p className="merchants-how_quoteText">See results in <em><b>less that 15 minutes</b></em> </p> */}
          <div className="growthChecklistTY-list-container">
            <div className="growthChecklistTY-list-section">
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="36px"
                height="36px"
                viewBox="0 0 24 24"
                aria-labelledby="okIconTitle"
                stroke="#4D75CB"
                stroke-width="2"
                stroke-linecap="square"
                stroke-linejoin="miter"
                fill="none"
                color="#4D75CB"
              >
                {" "}
                <title id="okIconTitle">Ok</title>{" "}
                <polyline points="4 13 9 18 20 7" />{" "}
              </svg>
              <h3 className="growthChecklistTY-list-text">
                Less than 2 minute size guide setup
              </h3>
            </div>

            <div className="growthChecklistTY-list-section">
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="36px"
                height="36px"
                viewBox="0 0 24 24"
                aria-labelledby="okIconTitle"
                stroke="#4D75CB"
                stroke-width="2"
                stroke-linecap="square"
                stroke-linejoin="miter"
                fill="none"
                color="#4D75CB"
              >
                {" "}
                <title id="okIconTitle">Ok</title>{" "}
                <polyline points="4 13 9 18 20 7" />{" "}
              </svg>
              <h3 className="growthChecklistTY-list-text">
                Up to 23% increase AOV
              </h3>
            </div>
            <div className="growthChecklistTY-list-section">
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="36px"
                height="36px"
                viewBox="0 0 24 24"
                aria-labelledby="okIconTitle"
                stroke="#4D75CB"
                stroke-width="2"
                stroke-linecap="square"
                stroke-linejoin="miter"
                fill="none"
                color="#4D75CB"
              >
                {" "}
                <title id="okIconTitle">Ok</title>{" "}
                <polyline points="4 13 9 18 20 7" />{" "}
              </svg>
              <h3 className="growthChecklistTY-list-text">
                Up to 26% saving on return costs
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="merchants-header_container" style={{ paddingTop: "0" }}>
        <div
          className="merchants-hero_imageContainer"
          style={{ width: "100%" }}
        >
          <video
            autoplay="true"
            muted
            loop
            playsInline
            onContextMenu={"return false;"}
            preload="auto"
            style={{ width: "100%", maxHeight: "100%" }}
          >
            <source src={demoVideo} type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="merchants-section_bg">
        <div className="merchants-desktop">
          <div className="merchants-header_container">
            <div className="merchants-feature_textContainer">
              <h2 className="merchants-featured_title">{checklistTitle}</h2>

              {/* <h3 className="merchants-featured_subTitle">
                    {soloFeatureBody}
                  </h3>
                 */}
            </div>
            <div className="merchants-header_imageContainer">
              {checklistNeeds}
            </div>
          </div>
        </div>
        <div className="merchants-mobile">
          <div className="merchants-header_container">
            <div className="merchants-feature_textContainer">
              <h2 className="merchants-featured_title">{checklistTitle}</h2>

              {/* <h3 className="merchants-featured_subTitle">
                    {soloFeatureBody}
                  </h3> */}
            </div>
            <div className="merchants-header_imageContainer">
              {checklistNeeds}
            </div>
          </div>
        </div>
      </div>
      <div className="merchants-section_bg" style={{ paddingBottom: "2rem" }}>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div style={{ width: "48rem" }}>
            <ReactCompareImage
              hover="true"
              handleSize={80}
              sliderLineColor="#f8dc54"
              leftImage={beforeCompare}
              leftImageAlt="size chart before"
              rightImage={afterCompare}
              rightImageAlt="size recommendations after"
            />
          </div>
        </div>
      </div>

      {/* <div className="merchants-section_bg" >
          <div className="merchants-desktop">
              <div className="merchants-header_container">
                <div className="merchants-header_imageContainer">
            
                <Img fluid={emailReturn} />
                </div>
                <div className="merchants-feature_textContainer">
                <h2 className="merchants-featured_title">{soloFeatureTitle}</h2>

                  <h3 className="merchants-featured_subTitle">
                    {soloFeatureBody}
                  </h3>
                
                </div>
              </div>
             
          </div>
          <div className="merchants-mobile">
          <div className="merchants-header_container">
             
                <div className="merchants-feature_textContainer">
                <h2 className="merchants-featured_title">{soloFeatureTitle}</h2>

                  <h3 className="merchants-featured_subTitle">
                    {soloFeatureBody}
                  </h3>
                
                </div>
                <div className="merchants-header_imageContainer">
            
                  <Img fluid={emailReturn} />
                </div>
              </div>
          </div>
        </div> */}
      {/* <div className="merchants-section_bg" >
        <div className='merchants-how_container' >
      
          <h2 className="merchants-how_title">
           Sizify is the clothing fit solution for online boutiques, online creators and DTC brands
          </h2>
          <div className='merchants-how_blurb'>
          <svg width="96px" height="96px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-labelledby="cartAddIconTitle" stroke="#4D75CB" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#4D75CB"> <title id="cartAddIconTitle">Add do cart</title> <path d="M21.2922 6L19.9463 14.1627C19.8666 14.6457 19.4491 15 18.9596 15H7.04304C6.55355 15 6.136 14.6457 6.05636 14.1627L4.84851 6.83731C4.76887 6.35434 4.35133 6 3.86183 6H2"/> <path d="M8 20C8.55228 20 9 19.5523 9 19C9 18.4477 8.55228 18 8 18C7.44772 18 7 18.4477 7 19C7 19.5523 7.44772 20 8 20Z"/> <path d="M18 20C18.5523 20 19 19.5523 19 19C19 18.4477 18.5523 18 18 18C17.4477 18 17 18.4477 17 19C17 19.5523 17.4477 20 18 20Z"/> <path d="M16 8H10"/> <path d="M13 5V11"/> </svg>
            <h3 className='merchants-how_blurbText'>
            Increase revenue
            </h3>
            
          </div>
          <div className='merchants-how_blurb'>
          <svg role="img" xmlns="http://www.w3.org/2000/svg" width="96px" height="96px" viewBox="0 0 24 24" aria-labelledby="trendingDownIconTitle" stroke="#4D75CB" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#4D75CB"> <title id="trendingDownIconTitle">trending</title> <path d="M3 7l6 6 4-4 7.405 7.405"/> <path stroke-linecap="round" d="M20.405 16.405L21 17"/> <path d="M21 14v3h-3"/> </svg>
             <h3 className='merchants-how_blurbText'>
            Reduce returns
            </h3>
      
          </div>
          <div className='merchants-how_blurb'>
          <svg width="96px" height="96px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-labelledby="supportIconTitle" stroke="#4D75CB" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#4D75CB"> <title id="supportIconTitle">Support</title> <path stroke-linecap="round" d="M11 8L9.42229 7.21115C9.14458 7.07229 8.83835 7 8.52786 7H7.82843C7.29799 7 6.78929 7.21071 6.41421 7.58579L5.58579 8.41421C5.21071 8.78929 5 9.29799 5 9.82843L5 14.9296C5 15.5983 5.3342 16.2228 5.8906 16.5937L9.75746 19.1716C10.4944 19.663 11.4668 19.611 12.1472 19.044L17 15"/> <path d="M14.4549 12.9142C13.8515 12.1062 12.741 11.8739 11.8643 12.3721L10.009 13.4266C9.41298 13.7653 8.66412 13.6641 8.17937 13.1794V13.1794C7.54605 12.546 7.59324 11.5056 8.2813 10.9323L12.4437 7.46356C12.8032 7.16403 13.2562 7 13.7241 7H14.5279C14.8384 7 15.1446 7.07229 15.4223 7.21115L17.8944 8.44721C18.572 8.786 19 9.47852 19 10.2361L19 12.9796C19 14.9037 16.5489 15.718 15.3976 14.1764L14.4549 12.9142Z"/> <path d="M1 17V8"/> <path d="M1 17V8"/> <path d="M23 17V8"/> </svg>
            <h3 className='merchants-how_blurbText'>
              Customer satisfaction boosts LTV
            </h3>
          
          </div>

            

        </div>
      </div> */}
      <div>
        <div className="merchants-how_container">
          <h2 className="merchants-how_title">
            What are our customers saying?
          </h2>
          <div
            className="merchants-header_imageContainer"
            style={{ padding: "0 3rem" }}
          >
            <Img fluid={feature6} />
          </div>
          <div
            className="merchants-how_blurbContainer"
            style={{ padding: "0 1rem" }}
          >
            <div className="merchants-how_blurbLeft">
              <h3 className="merchants-how_quoteText">
                "It is going great! People are using it. I forgot to add it to
                one particular product and had some one ask about the size guide
                so that’s what tip me off that it’s truly working. I love it!"
              </h3>
              <p className="merchants-how-blurbQuote" style={{ margin: "0" }}>
                - Valerie Wellington
              </p>
              <p className="merchants-how-blurbQuote" style={{ margin: "0" }}>
                Owner, Lava Luxe
              </p>
            </div>
            <div className="merchants-how_blurbLeft">
              <h3 className="merchants-how_quoteText">
                "We love the concept of Sizify it was a smooth process and made
                creating a size chart for our product pages easier."
              </h3>
              <p className="merchants-how-blurbQuote" style={{ margin: "0" }}>
                - Chris Parks
              </p>
              <p className="merchants-how-blurbQuote">CMO, Moto MP</p>
            </div>
            <div className="merchants-how_blurbLeft">
              <h3 className="merchants-how_quoteText">
                "Adding it to my store was really easy! It was the missing piece
                to my product page. Thanks so much."
              </h3>
              <p className="merchants-how-blurbQuote" style={{ margin: "0" }}>
                - Juliana May
              </p>
              <p className="merchants-how-blurbQuote">CEO, Belle Bonjour</p>
            </div>
          </div>
        </div>
      </div>
      <div className="merchants-how_bg">
        <div className="merchants-proof_container">
          <div className="merchants-proof_img">
            <Img fixed={shopifyLogo} />
          </div>
          <h3
            className="merchants-proof_title"
            style={{ textAlign: "center", width: "100%" }}
          >
            Featured in:
          </h3>
          <h3
            className="merchants-featured_title"
            style={{ textAlign: "center", width: "100%" }}
          >
            "Trending Apps of 2021"
          </h3>
          <h3
            className="merchants-proof_title"
            style={{ textAlign: "center", width: "100%" }}
          >
            on Shopify App Store
          </h3>
        </div>
      </div>
      {/* <div className="merchants-how_bg" style={{backgroundColor:'hsla(210, 67%, 99%, 1)'}}>
        <div className='merchants-proof_container' >
     
            <h3 className="merchants-featured_title" style={{textAlign:'center', width:'100%', marginTop:'1rem'}}>
            Demo Video 
            </h3>
            <div className="homepage-vid-container">
            <iframe style={{height:'100%'}} src="https://player.vimeo.com/video/702969877?h=f2a04233e5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1920" height="1080" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Sizify Demo"></iframe>
          
            </div>
           
        </div>
   

        </div> */}
      <div className="merchants-desktop">
        <div className="merchants-header_container">
          <div className="merchants-header_imageContainer">
            <Img fluid={feature1} />
          </div>
          <div className="merchants-feature_textContainer">
            <h2 className="merchants-featured_title">{featureTitle1}</h2>

            <h3 className="merchants-featured_subTitle">{featureBody1}</h3>
          </div>
        </div>
        <div className="merchants-header_container">
          <div className="merchants-feature_textContainer">
            <h2 className="merchants-featured_title">{featureTitle2}</h2>

            <h3 className="merchants-featured_subTitle">{featureBody2}</h3>
          </div>
          <div className="merchants-header_imageContainer">
            <Img fluid={feature2} />
          </div>
        </div>
        <div className="merchants-header_container">
          <div className="merchants-header_imageContainer">
            <Img fluid={feature3} />
          </div>
          <div className="merchants-feature_textContainer">
            <h2 className="merchants-featured_title">{featureTitle3}</h2>

            <h3 className="merchants-featured_subTitle">{featureBody3}</h3>
          </div>
        </div>
        <div className="merchants-header_container">
          <div className="merchants-feature_textContainer">
            <h2 className="merchants-featured_title">{featureTitle4}</h2>

            <h3 className="merchants-featured_subTitle">{featureBody4}</h3>
          </div>
          <div className="merchants-header_imageContainer">
            <Img fluid={feature4} />
          </div>
        </div>
      </div>
      <div className="merchants-mobile">
        <div className="merchants-header_container">
          <div className="merchants-feature_textContainer">
            <h2 className="merchants-featured_title">{featureTitle1}</h2>

            <h3 className="merchants-featured_subTitle">{featureBody1}</h3>
          </div>
          <div className="merchants-header_imageContainer">
            <Img fluid={feature1} />
          </div>
        </div>
        <div className="merchants-header_container">
          <div className="merchants-feature_textContainer">
            <h2 className="merchants-featured_title">{featureTitle2}</h2>

            <h3 className="merchants-featured_subTitle">{featureBody2}</h3>
          </div>
          <div className="merchants-header_imageContainer">
            <Img fluid={feature2} />
          </div>
        </div>
        <div className="merchants-header_container">
          <div className="merchants-feature_textContainer">
            <h2 className="merchants-featured_title">{featureTitle3}</h2>

            <h3 className="merchants-featured_subTitle">{featureBody3}</h3>
          </div>
          <div className="merchants-header_imageContainer">
            <Img fluid={feature3} />
          </div>
        </div>
        <div className="merchants-header_container">
          <div className="merchants-feature_textContainer">
            <h2 className="merchants-featured_title">{featureTitle4}</h2>

            <h3 className="merchants-featured_subTitle">{featureBody4}</h3>
          </div>
          <div className="merchants-header_imageContainer">
            <Img fluid={feature4} />
          </div>
        </div>
      </div>
      {/* <div className="merchants-section_bg" style={{backgroundColor:"hsla(210, 67%, 99%, 1)"}}>
      <div className="merchants-guarantee_container" >
      <div className="merchants-feature_textContainer" style={{width:'100%'}}>
        <h3 className="merchants-proof_title" style={{textAlign:'center', lineHeight:'1.25', fontWeight:'400'}}> 
              We'll Handle Onboarding For You + <span className="nobr">30 Day Money-Back Guarantee</span>   
            </h3>

      </div>
       
      </div>
      
      </div> */}
      {/* <div className="merchants-how_bg">
        <div className='merchants-how_container' >
      
          <h2 className="merchants-how_title">
           How it works
          </h2>
          <div className='merchants-how_blurb'>
          <svg width="96px" height="96px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-labelledby="supportIconTitle" stroke="#fcf0b6" stroke-width="1" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#fcf0b6"> <title id="supportIconTitle">Support</title> <path stroke-linecap="round" d="M11 8L9.42229 7.21115C9.14458 7.07229 8.83835 7 8.52786 7H7.82843C7.29799 7 6.78929 7.21071 6.41421 7.58579L5.58579 8.41421C5.21071 8.78929 5 9.29799 5 9.82843L5 14.9296C5 15.5983 5.3342 16.2228 5.8906 16.5937L9.75746 19.1716C10.4944 19.663 11.4668 19.611 12.1472 19.044L17 15"/> <path d="M14.4549 12.9142C13.8515 12.1062 12.741 11.8739 11.8643 12.3721L10.009 13.4266C9.41298 13.7653 8.66412 13.6641 8.17937 13.1794V13.1794C7.54605 12.546 7.59324 11.5056 8.2813 10.9323L12.4437 7.46356C12.8032 7.16403 13.2562 7 13.7241 7H14.5279C14.8384 7 15.1446 7.07229 15.4223 7.21115L17.8944 8.44721C18.572 8.786 19 9.47852 19 10.2361L19 12.9796C19 14.9037 16.5489 15.718 15.3976 14.1764L14.4549 12.9142Z"/> <path d="M1 17V8"/> <path d="M1 17V8"/> <path d="M23 17V8"/> </svg>
            <h3 className='merchants-how_blurbText'>
            Integrate Sizify with your store
            </h3>
            
          </div>
          <div className='merchants-how_blurb'>
          <svg role="img" xmlns="http://www.w3.org/2000/svg" width="96px" height="96px" viewBox="0 0 24 24" aria-labelledby="feedIconTitle" stroke="#fcf0b6" stroke-width="1" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#fcf0b6"> <title id="feedIconTitle">Feed</title> <circle cx="7.5" cy="7.5" r="2.5"/> <path d="M22 13H2"/> <path d="M18 6h-5m5 3h-5"/> <path d="M5 2h14a3 3 0 0 1 3 3v17H2V5a3 3 0 0 1 3-3z"/> </svg>
             <h3 className='merchants-how_blurbText'>
            Shoppers interact with Sizify widget on your product pages
            </h3>
      
          </div>
          <div className='merchants-how_blurb'>
          <svg width="96px" height="96px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-labelledby="cartAddIconTitle" stroke="#fcf0b6" stroke-width="1" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#fcf0b6"> <title id="cartAddIconTitle">Add do cart</title> <path d="M21.2922 6L19.9463 14.1627C19.8666 14.6457 19.4491 15 18.9596 15H7.04304C6.55355 15 6.136 14.6457 6.05636 14.1627L4.84851 6.83731C4.76887 6.35434 4.35133 6 3.86183 6H2"/> <path d="M8 20C8.55228 20 9 19.5523 9 19C9 18.4477 8.55228 18 8 18C7.44772 18 7 18.4477 7 19C7 19.5523 7.44772 20 8 20Z"/> <path d="M18 20C18.5523 20 19 19.5523 19 19C19 18.4477 18.5523 18 18 18C17.4477 18 17 18.4477 17 19C17 19.5523 17.4477 20 18 20Z"/> <path d="M16 8H10"/> <path d="M13 5V11"/> </svg>
            <h3 className='merchants-how_blurbText'>
              Customers start getting clothing size recommendations as they shop
            </h3>
          
          </div>
    

        </div>
      </div> */}
      {/* <div className="merchants-how_bg">
        <div className='merchants-how_container' >
        <h2 className="merchants-how_title">
           Getting started is easy
          </h2>
          <div className="merchants-how_blurbContainer" >
              <div className='merchants-how_blurbLeft'>
              <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" aria-labelledby="stopwatchIconTitle" stroke="#4D75CB" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#f8dc54"> <title id="stopwatchIconTitle">Stopwatch</title> <circle cx="12" cy="13" r="8"/> <path d="M12 9L12 13M18 7L20 5M15 2L9 2"/> </svg>
                  <h3 className='merchants-how_blurbTextLeft'>
                  Create first size guide in under 5 minutes
                  </h3>
                  <p className="merchants-how-blurbPara">
                    Our step by step wizard makes setup simple.
                  </p>
                
              </div>
              <div className='merchants-how_blurbLeft'>
              <svg width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="codeAltIconTitle" stroke="#4D75CB" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" color="#f8dc54"> <title id="codeAltIconTitle">Code</title> <path d="M7 8L3 12L7 16"/> <path d="M17 16L21 12L17 8"/> <path d="M9 19.5L14.5 5"/> </svg>
                  <h3 className='merchants-how_blurbTextLeft '>
                  1-click theme setup. No developer needed
                  </h3>
                  <p className="merchants-how-blurbPara">
                    Automatic integration for most themes. With additional customization options available.
                  </p>
            
              </div>
              <div className='merchants-how_blurbLeft'>
              <svg role="img" xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 24 24" aria-labelledby="chatIconTitle" stroke="#4D75CB" stroke-width="2" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#f8dc54"> <title id="chatIconTitle">Chat</title> <path d="M8.82388455,18.5880577 L4,21 L4.65322944,16.4273939 C3.00629211,15.0013 2,13.0946628 2,11 C2,6.581722 6.4771525,3 12,3 C17.5228475,3 22,6.581722 22,11 C22,15.418278 17.5228475,19 12,19 C10.8897425,19 9.82174472,18.8552518 8.82388455,18.5880577 Z"/> </svg>
                  <h3 className='merchants-how_blurbTextLeft '>
                    Additional documentation & support. 
                  </h3>
                  <p className="merchants-how-blurbPara">
                  Need added support? Checkout our help docs. Plus our support team is here to help
                  </p>
            
              </div>
      
            </div>
              <div className="merchants-header_imageContainer" >
              <Img  
                fluid={feature5}
            />
            </div>
          </div>
      </div> */}

      <div className="merchants-section_bg">
        <div className="merchants-section_containerCta2">
          <h2 className="merchants-header_titleCta2 ">
            Your Shopify apparel store isn't complete without fit-finder
            technology
          </h2>
          <div id="script-container"></div>
        </div>
      </div>
      <div className="merchants-how_bg">
        <div className="merchants-proof_container">
          <h3
            className="merchants-proof_title"
            style={{ textAlign: "center", width: "100%" }}
          >
            Proud to be official partners
          </h3>
          <div className="merchants-proof_img">
            <Img fixed={shopifyPartners} />
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Homepage

export const query = graphql`
  query {
    heroImg: file(relativePath: { eq: "phoneImg.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    feature1: file(relativePath: { eq: "size-network-3.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    feature2: file(relativePath: { eq: "2in1-size-guide.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    feature3: file(relativePath: { eq: "recommendations.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    feature4: file(relativePath: { eq: "size-discovery.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    feature5: file(relativePath: { eq: "woman-on-computer.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    feature6: file(relativePath: { eq: "cx-logos-2.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shopifyLogo: file(relativePath: { eq: "shopify-glyph.png" }) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    emailReturn: file(relativePath: { eq: "email-request-2.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    shopifyPartners: file(relativePath: { eq: "shopify-partner.png" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cxLogo1: file(relativePath: { eq: "cx-logo-profile1.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cxLogo2: file(relativePath: { eq: "cx-logo-profile2.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cxLogo3: file(relativePath: { eq: "cx-logo-profile3.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
